import { displayHttpError } from "../../../../../components/errors/HttpErrorNotice";
import { CustomBranding } from "../../../../../components/NavBar";
import { defined } from "../../../../core/defined";
import { Milliseconds } from "../../../../core/time";
import { GeographiesSerializable } from "../../../../domain/geography";
import { dataURLtoBlob } from "../../../exports/dataUrlToBlob";
import { uploadPublicImageBlob } from "../../../requests/common_requests";
import {
  DocCardMicro,
  DocCardState,
  DocCardStats,
} from "../document-core/core";
import {
  PackagedDocCardDataDto,
  PackagedDocMicroCardDataDto,
  PackagedDocumentDto,
} from "./types";

export const createMicroCardImageEventName = "createMicroCardImage";
export const renderMicroCardAndExtractImageEventName =
  "renderMicroCardAndExtractImage";
const microCardImageCreatedEventName = "microCardImageCreated";
export function emitMicroCardImageCreatedEvent(
  cardId: string,
  dataUri: string
) {
  document.dispatchEvent(
    new CustomEvent(microCardImageCreatedEventName, {
      detail: {
        cardId,
        dataUri,
      },
    })
  );
}

export async function makePackagedDocument(
  docName: string,
  geographies: GeographiesSerializable,
  cards: DocCardState[],
  organizationBranding: CustomBranding | undefined,
  reportProgress: (current: number, total: number) => void
): Promise<PackagedDocumentDto> {
  const cardData = [];

  for (const card of cards) {
    let data;
    switch (card.type) {
      case "error":
      case "textCardCK":
      case "textCardSimple":
        // Skip these card types as before
        reportProgress(cardData.length, cards.length);
        break;
      case "dataCard":
        cardData.push(await packDataCard(card));
        reportProgress(cardData.length, cards.length);
        break;
      case "microCard":
        if (card.data.selectedTab === "map-select") {
          throw new Error(
            "map-select is not supported for published micro cards"
          );
        }
        switch (card.data.selectedTab) {
          case "map-view":
            data = await generateMicroImageData(card);
            break;
          case "chart":
          case "info":
          case "table":
            data = packMicroCard(card);
            break;
        }
        cardData.push(data);
        reportProgress(cardData.length, cards.length);
        break;
    }
  }

  return {
    customBranding: organizationBranding,
    documentName: docName,
    geographies: geographies.itemsList,
    cardData: cardData.filter(defined),
  };
}

function generateMicroImageData(card: DocCardMicro) {
  return new Promise<PackagedDocMicroCardDataDto>((resolve, reject) => {
    setTimeout(() => {
      reject("Timed out: " + card.id);
    }, Milliseconds.second * 15);

    const listener = (event: any) => {
      if (event.detail.cardId !== card.id) {
        return;
      }

      document.removeEventListener(microCardImageCreatedEventName, listener);
      if (!defined(event.detail.dataUri)) {
        reject("no dataUri in event detail");
        return;
      }

      uploadPublicImageBlob(dataURLtoBlob(event.detail.dataUri)).then(
        (urlRes) => {
          urlRes.match({
            ok: (url) => {
              resolve({
                id: card.id,
                type: "microCardImage",
                imgUrl: url,
              });
            },
            err: (err) => {
              throw new Error(displayHttpError(err));
            },
          });
        }
      );
    };

    document.addEventListener(microCardImageCreatedEventName, listener);

    document.dispatchEvent(
      new CustomEvent(renderMicroCardAndExtractImageEventName, {
        detail: {
          cardId: card.id,
        },
      })
    );
  });
}

function packMicroCard(card: DocCardMicro): PackagedDocMicroCardDataDto {
  const loadedData = card.data.loadedData;
  const chartData = loadedData?.chartDataState?.loadedChartData;
  const dataset = loadedData?.primaryDataset;
  return {
    id: card.id,
    type: "microCardNonImage",
    selectedTab: card.data.selectedTab,
    dataset: dataset?.packDatasetForPublication(),
    chartDataState: defined(chartData)
      ? {
          colorSchemeContainer: chartData.colorSchemeContainer,
        }
      : undefined,
  };
}

function packDataCard(card: DocCardStats): PackagedDocCardDataDto {
  const loadedData = card.data.loadedData;
  const chartData = loadedData?.chartDataState?.loadedChartData;
  const dataset = loadedData?.dataset;
  return {
    id: card.id,
    type: card.type,
    dataset: dataset?.packDatasetForPublication(),
    chartDataState: defined(chartData)
      ? {
          colorSchemeContainer: chartData.colorSchemeContainer,
        }
      : undefined,
    dataSelections: card.data.dataSelections,
    groupingSelection: card.data.groupingSelection,
  };
}
